:root {
    --fbs-rating-img-size: 7rem;
    --fbs-rating-block-blur: 10px;
    --fbs-rating-block-blur-low: 2px;
    --mobile-min-width: 350px;
}
body { min-width: var(--mobile-min-width)}

* { box-sizing: border-box }
a { text-decoration: none }
b { font-weight: 500 }

.bg-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
}
.ratings-container {
    width: 90%;
    max-width: 1280px;
    margin: 0 auto;
}
#fixed-right-top {
    position: absolute;
    padding: 10px 20px;
    right: 0;
    top: 0;
}
#header,
#footer {
    position: absolute;
    padding: 15px 20px 10px;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
#fbs-logout-input{
    width: 100%;
    display: block;
    padding: 10px;
    font-size: 1.1rem;
    margin-bottom: 3px;
    border-radius: 4px;
    background: rgba(0,0,0,0.1);
    border: 3px solid transparent;
}
#logo img {
    height: 90px;
    object-fit: contain;
    margin-top: 1rem;
}
#footer {
    color: white;
    top: auto;
    bottom: 0;
}
.shadow-vlight,
.footer-text-visible {
    text-shadow: 1px 1px 1px #00000054;
}

.fbs-rating-heading{
    margin-bottom:2rem;
    font-weight:500;
    font-size: 28px;
    margin-top: 3rem;
}

.fbs-rating-img {
    width: var(--fbs-rating-img-size);
    height: var(--fbs-rating-img-size);
    object-fit: cover;
}
.fbs-rating-animation{
    width: 12rem;
    height: 12rem;
    object-fit: cover;
}
.fbs-rating-title {
    display: block;
    padding: 10px;
    font-weight: 600;
    font-size: 20px; /* was:1.55rem */
    line-height: 26px;
    margin: auto;
    margin-top: 15px;
    max-width: 180px;
    text-overflow: ellipsis;
    white-space: break-spaces;
    word-break: initial;
}
.fbs-rating-blocks {
    display: flex;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
}
.fbs-rating-block {
    min-width: 220px;
    display: block;
    margin: 0;
    padding: 20px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
}
.fbs-items{
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    padding:5px 5px;
    /* flex-wrap:wrap; */
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 400px;
    min-height: 400px;
    width: 100%;
    place-items: center;
}
.fbs-item{
    min-width: 200px;
}
.fbs-item .fbs-rating-img{
    max-width: 4.5rem;
    max-height: 4.5rem;
}
.fbs-header-issue-section{
    margin-bottom: 2rem;
    margin-top: 5rem;
    font-weight: 500;
}
.fbs-header-issue-section-sub{
    margin-bottom: 2rem;
    margin-top: 5rem;
    font-weight: 500;
}
@media screen and (max-height:700px) {
    .fbs-items{
        max-height: 300px;
        min-height: 300px;
    }
    .ratings-container{
        margin-top: 20px;
    }
}
@media screen and (min-width:1720px){
    .fbs-rating-img{
        height: 10rem;
        width: 10rem;
    }
    .fbs-rating-animation{
        height: 15rem;
        width: 15rem;
    }
    .fbs-rating-block{
        min-width: 300px;
    }
    .fbs-items{
        max-height: 500px;
        min-height: 500px;
    }
    .fbs-item{
        min-width: 300px;
    }
    .fbs-item .fbs-rating-img{
        max-width: 7rem;
        max-height: 7rem;
    }
}
@media screen and (max-width:1280px){
    .fbs-rating-block{
        min-width: 180px;
    }
    .fbs-rating-img{
       height: 6rem;
       width: 6rem;
    }
    .fbs-rating-animation{
        height: 10rem;
        width: 10rem;
    }
    .fbs-rating-title{
         font-size: 18px;
    }
    #logo img{
        height: 80px;
    }
    .ratings-container{
        max-width: 1024px;
    }
    .fbs-header-issue-section{
        font-size: 20px;
    }
    .fbs-header-issue-section-sub{
        font-size: 20px;
    }
    
}
@media screen and (max-width:1024px){
    .fbs-rating-block{
        min-width: 160px;
    }
    .fbs-rating-img{
       height: 5rem;
       width: 5rem;
    }
    .fbs-rating-animation{
        height: 9rem;
        width: 9rem;
    }
    #logo img{
        height: 74px;
    }
    .fbs-rating-heading{
        font-size: 24px;
        margin-top: 3rem;
    }
    .ratings-container{
        max-width: 800px;
    }
    .fbs-item{
        padding: 10px;
    }
    .fbs-item .fbs-rating-img{
        max-width: 3.5rem;
        max-height: 3.5rem;
    }
}
@media screen and (max-width:850px){
    .fbs-rating-block{
        min-width: 150px;
    }
}

.audio-recorder-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    margin-top: 5px;
    /* background-color: rgb(233, 230, 230); */
    /* border-radius: 5px; */
}

.record-btn{
    padding: 10px;
    background-color: transparent;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border: none;
    font-size: 15px;
}
.record-btn:hover{
    cursor: pointer;
}
.start-rec{
    background-color: rgb(89, 156, 89);
}
.restart-rec{
    background-color: rgb(171, 169, 169);
}
.stop-rec{
   background-color: rgb(193, 104, 104);
}
.playaback{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.feedback-text{
    display: flex;
    justify-content: space-between;
}
.audio-toggle{
    display: flex;
    align-items: center;
    gap: 5px;
}

.fbs-rating-block:not(.is-selcted) {
    -webkit-backdrop-filter: blur(var(--fbs-rating-block-blur-low));  /* Safari 9+ */
    backdrop-filter: blur(var(--fbs-rating-block-blur-low)); /* Chrome and Opera */
}
.bg-glass,
.isSelected {
    border-radius: 4px;
    border: 2px solid rgba(255,255,255,0.75);
    -webkit-backdrop-filter: blur(var(--fbs-rating-block-blur));  /* Safari 9+ */
    backdrop-filter: blur(var(--fbs-rating-block-blur)); /* Chrome and Opera */
    box-shadow: inset 0 0 0 1px rgba(255,255,255,0.086);
    background: rgba(255,255,255,0.56);
}
.isSelected { padding-top: 30px }
/* Selected Rating */
.isSelected *, .isAnimated {
    transition: all 0.175s ease-in;
}
.isSelected .fbs-rating-img,
.isSelected .fbs-rating-title {
    transform: scale(1.2);
    color: #2196f3
}
/* Forms */
label { padding-bottom: 5px; display: inline-block }

/* Rating form */
.fbs-form {
    width: 100%;
    min-width: 600px;
    display: block;
    text-align: left;
    padding: 20px;
    border-radius: 4px;
    border-width: 0;
    box-shadow: 8px 8px 10px rgba(0,0,0,0.025);
}
.fbs-form-section-sub {
    display: block;
    margin: 0 0 15px 0;
}
.fbs-form-input-label {
    display: block;
    padding: 0 0 8px;
    font-size: 1rem;
    margin-top: 10px;
}
.fbs-form-input {
    width: 100%;
    display: block;
    padding: 15px;
    font-size: 1.2rem;
    border-radius: 4px;
    background: rgba(0,0,0,0.1);
    border: 3px solid transparent;
}
.fbs-form-input:focus {
    border-color: rgba(0,0,0,0.45);
}
.fbs-form-input::-webkit-input-placeholder {
    font-size: 0.9rem;
    line-height: 1.5;
}
.fbs-form-textarea {
    resize: none;
    font-family: 'Open Sans', 'Segoe UI', 'Verdana', sans-serif;
}
.fbs-form-text-input {
}

.fbs-2x-grid {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 25px;
}

.fbs-submit-form{
    margin-top: 100px;
    max-height: 70vh;
    overflow-y: auto;
}
/* Animation fix */
.is-transitioning {
    display: none;
}

/* Number input: Disable browser auto-increment for Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.animFace {
    max-width: 200px;
    max-height: 200px;
    width: 116px;
    height: 116px;
    padding: 12px;
    box-sizing: border-box;
    margin-top: 10px;
    z-index: 0;
    background: rgb(255 185 46 / 76%);
    border-radius: 50%;
    border: 2px solid;
}

/* QR Code page */
.fbs-block-prominent {
    border-radius: 7px;
    background: rgba(255,255,255,0.8);
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
}

#header-right img{
    max-height: 90px;
}



/* MOBILE */
.app-container-mobile {
    height: 100vh;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 auto;
}
.is-mobile #header {
    padding: 1.5em 0 0;
    position: static !important;
}
.is-mobile .fbs-rating-blocks {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 100% !important;
}

.is-mobile .isSelected { padding: 4px !important; }
.is-mobile .isSelected .fbs-rating-img,
.is-mobile .isSelected .fbs-rating-title {
    transform: scale(1.05);
}

.is-mobile .fbs-rating-blocks label {
    width: 100%; /* make the .fbs-rating-block 100% width work */
    padding-bottom: 10px;
}
.is-mobile .fbs-rating-block {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 15px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
}
.is-mobile .fbs-rating-img {
    width: calc(var(--fbs-rating-img-size)/1.8);
    height: calc(var(--fbs-rating-img-size)/1.8);
}
.is-mobile .fbs-rating-animation {
    width: calc(var(--fbs-rating-img-size)/1.4);
    height: calc(var(--fbs-rating-img-size)/1.4);
}
.is-mobile .fbs-rating-title {
    margin:0;
    padding-left: 15px;
    max-width: 100%;
    text-align: left;
    overflow: hidden;
}
.is-mobile .fbs-form {
    min-width: var(--mobile-min-width)/1.5;
    padding-top: 5px;
}
.is-mobile .fbs-submit-form{
    margin-top: 0px;
    max-height: fit-content;
}

.is-mobile .fbs-items{
    padding: 10px 10px;
    flex-wrap:nowrap;
    overflow: auto;
    max-height: fit-content;
    min-height: fit-content;
    width: 100%;
    justify-content: flex-start;
}

.is-mobile .fbs-header-issue-section{
    margin-top: 2rem;
}
.is-mobile .fbs-header-issue-section-sub{
    margin-top: 2rem;
}

/* QR Page */
.fbs-loc-staff-profile-img {
    border-radius: 50%;
    width: 256px;
    height: 256px;
    object-fit: cover;
    border: 5px solid rgba(255, 255, 255, 0.525);
    z-index: 10;
    position: relative;
    text-align: center;
}
.fbs-loc-staff-profile-img-rating {
    width: 128px;
    height: 128px;
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
}
.fbs-staff-on-duty__rating {
    position: absolute;
    top: 24px;
    left: 28px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 1;
    text-align: center;
    z-index: 0;
}
.fbs-staff-on-duty__rating-header {
    font-size: 1.2rem;
    line-height: 1;
    padding: 0; 
    font-weight: 600;
    margin: 10px 0;
}

.fbs-staff-on-duty__isMobile .fbs-staff-on-duty__rating-header {
    display: none;
}
.fbs-staff-on-duty__isMobile .fbs-loc-staff-profile-img {
    border-width: 3px;
}
.fbs-staff-on-duty__isMobile .fbs-loc-staff-profile-img-rating {
    width: 45px;
    height: 45px;
}

.tnc-label {
    vertical-align: middle;
    background: rgba(0,0,0,0.0275);
    line-height: 1.5;
    padding: 5px 9px 5px 5px;
    border-radius: 5px;
}

/* Experimental */
.open-links-in-new-tab a {
    target-new: window;
}

/* OTP */
.button-otp {
    font-size: 0.9rem !important;
}
/* OTP Button Positioning */
.otp-validate-btn-container {
    position: absolute;
    right: 7px;
    top: 35px;
}
.otp-verify-btn-container {
    position: absolute;
    right: 3px;
    top: 30px;
}

/* ============================================= */
/* =============== CUSTOM THEMES =============== */
/* ============================================= */

/* Commons */
.theme-bg-img {
    background-color: #fff;
}

/* ============================================= */
/* VIOLET - AD THEME */
/* ============================================= */
.theme-custom-violet #logo img {
    height: 98px;
    width: auto;
}
.theme-custom-violet .theme-bg-img,
.theme-custom-violet .app-container-tablet {
    background: #fff5fe url(/src/assets/fbs/ad/bg_ad_partial_flightillustration_transp_light.png) no-repeat bottom left / cover !important;
}
.theme-custom-violet .app-container-mobile {
    background: #fff5fe url(/src/assets/fbs/ad/bg_ad_partial_flightillustration_transp_light.png) no-repeat bottom left / cover !important;
    /* bg with bottom = bg_ad_mob */
}
.theme-custom-violet .theme-primary,
.theme-custom-violet .isSelected.theme-primary .fbs-rating-img,
.theme-custom-violet .isSelected.theme-primary .fbs-rating-title 
{
    color: #8D278F !important;
}
.theme-custom-violet .theme-primary-bg {
    background-color: #8D278F !important;
}
.theme-custom-violet .theme-primary-bg-dark {
    background-color: #6a1d6b !important;
}
.theme-custom-violet #footer .footer-text-visible {
    font-size: 0.86rem;
    color: #787878;
    text-shadow: 1px 1px 0 #ffffff96 !important;
}

/* ============================================= */
/* BIAL THEME */
/* ============================================= */
.theme-custom-bial #logo img {
    height: 98px;
    width: auto;
}

.theme-custom-bial #header-right img{
    max-height: 120px;
}
.theme-custom-bial .theme-bg-img,
.theme-custom-bial .app-container-tablet {
    background: #FDFDFD url(/src/assets/fbs/bial/bg-rays.png) no-repeat left top / cover !important;
}
.theme-custom-bial .app-container-mobile {
    background: #FDFDFD url(/src/assets/fbs/bial/bg-rays.png) no-repeat bottom left / cover !important;
}
.theme-custom-bial .theme-primary,
.theme-custom-bial .isSelected.theme-primary .fbs-rating-img,
.theme-custom-bial .isSelected.theme-primary .fbs-rating-title,
.theme-custom-bial .fbs-greeting,
.theme-custom-bial .fbs-qr-heading
{
    color: #0BB3AB !important;
}
.theme-custom-bial .theme-primary-bg {
    background-color: #0BB3AB !important;
}
.theme-custom-bial .theme-primary-bg-dark {
    background-color: #DE7859 !important;
}
.theme-custom-bial #footer .footer-text-visible {
    font-size: 1rem;
    color: #040000;
    text-shadow: 1px 1px 0 #ffffff96 !important;
}
.theme-custom-bial .fbs-loc-staff-profile-img {
    border: 12px solid rgba(0,0,0,0.064);
}
.theme-custom-bial #footer {
    overflow: visible;
    min-height: 120px;
    background: transparent url(/src/assets/fbs/bial/footer-swish.png) no-repeat bottom right / contain !important;
    align-items: end;
}
/* .theme-custom-bial #footer-location{
   display: none;
} */
.theme-custom-bial .fbs-qr-container{
    margin-top: -50px;
}


@media screen and (min-width:1600px){
    .theme-custom-bial #footer {
        min-height: 180px;     
        background: transparent url(/src/assets/fbs/bial/footer-swish.png) no-repeat bottom right / cover !important;    
    }
    .theme-custom-bial #header-right img{
        max-height: 220px;
    }
    .theme-custom-bial .fbs-loc-staff-profile-img{
        width: 400px;
        height: 400px;
    }
    .theme-custom-bial .fbs-qr-heading{
        font-size: 30px;
    } 
    .theme-custom-bial .fbs-greeting{
        font-size: 30px;
    }
    .theme-custom-bial #footer{
        align-items: end;
    }
    .theme-custom-bial .fbs-qr-container{
        margin-top: -150px;
    }
}


/* ============================================= */
/* ENCALM THEME */
/* ============================================= */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 11, 2023 */
@font-face {
    font-family: 'brandon_grotesqueregular';
    src: url('/src/assets/fbs/encalm/brandon_reg-webfont.woff2') format('woff2'),
    url('/src/assets/fbs/encalm/brandon_reg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* Custom font for Encalm */
.theme-custom-encalm,
.theme-custom-encalm-light {
    font-family: 'brandon_grotesqueregular', Montserrat, sans-serif;
}

.theme-custom-encalm #logo img {
    height: 98px;
    width: auto;
}
.theme-custom-encalm .theme-bg-img,
.theme-custom-encalm .app-container-tablet {
    color: #c9a477 !important;
    background: #152347 url(/src/assets/fbs/encalm/encalm-bg-bl.png) no-repeat left bottom / cover !important;
}
.theme-custom-encalm .fbs-header-location {
    color: #FFF !important;
    opacity: 1 !important;
    font-weight: 400 !important;
}
.theme-custom-encalm .app-container-mobile {
    color: #c9a477 !important;
    background: #152347 url(/src/assets/fbs/encalm/encalm-bg-bl.png) no-repeat left bottom / contain !important;
}
.theme-custom-encalm .theme-primary,
.theme-custom-encalm .fbs-form-input,
.theme-custom-encalm .fbs-form-textarea
{
    color: #c9a477 !important;
}
.theme-custom-encalm .isSelected.theme-primary .fbs-rating-img,
.theme-custom-encalm .isSelected.theme-primary .fbs-rating-title {
    color: #152347 !important;
}
.theme-custom-encalm .theme-primary-bg {
    background-color: #c9a477 !important;
}
.theme-custom-encalm .theme-primary-bg-dark {
    background-color: #DE7859 !important;
}
.theme-custom-encalm #footer .footer-text-visible {
    font-size: 0.86rem;
    color: #c9a477;
    text-shadow: 1px 1px 0 #ffffff96 !important;
}
.theme-custom-encalm .fbs-loc-staff-profile-img {
    border: 12px solid rgba(0,0,0,0.064);
}
.theme-custom-encalm #footer {
    overflow: visible;
    min-height: 120px;
    background: transparent;
}
.theme-custom-encalm .bg-glass { background: transparent !important }

/* ---------------------------------- */
/* Encalm:: Light */
/* ---------------------------------- */

.theme-custom-encalm-light #logo img {
    height: 98px;
    width: auto;
}
.theme-custom-encalm-light .theme-bg-img,
.theme-custom-encalm-light .app-container-tablet,
.theme-custom-encalm-light .app-container-mobile {
    color: #152248 !important;
    background-color: #E7E2D9 !important;
}
.theme-custom-encalm-light .fbs-header-location {
    color: #152248 !important;
    opacity: 1 !important;
    font-weight: 500 !important;
    line-height: 1.45;
}

/* Customization for Encalm Issue Header / SubHeader */
.theme-custom-encalm-light .theme-color.fbs-header-issue-section,
.theme-custom-encalm-light .theme-color.fbs-header-issue-section-sub {
    color: #152248 !important;
    opacity: 1 !important;
    font-weight: 600 !important;
    line-height: 1.45;
    font-size: 1.64rem;
    
}

.theme-custom-encalm-light .fbs-ratings-main .fbs-rating-block .fbs-rating-img { display:none }
.theme-custom-encalm-light .is-mobile .fbs-ratings-main .fbs-rating-block { justify-content: center; font-weight: 600 !important; }
.theme-custom-encalm-light .is-mobile .fbs-ratings-main .fbs-rating-block:hover { box-shadow: 0 0 15px rgba(0,0,0,0.125) }

.theme-custom-encalm-light .fbs-ratings-main .fbs-rating-5 { background: #c9a477  ; }
.theme-custom-encalm-light .fbs-ratings-main .fbs-rating-4 { background: #c9a477b8; }
.theme-custom-encalm-light .fbs-ratings-main .fbs-rating-3 { background: #c9a47794; }
.theme-custom-encalm-light .fbs-ratings-main .fbs-rating-2 { background: #c9a47769; }
.theme-custom-encalm-light .fbs-ratings-main .fbs-rating-1 { background: #c9a4773b; }

.theme-custom-encalm-light .fbs-ratings-main .isSelected .fbs-rating-block {
    background: #152248;
}

.theme-custom-encalm-light .app-container-mobile {
    color: #152248 !important;
    background: #E7E2D9 url(/src/assets/fbs/encalm/encalm-pattern-gold-br.png) no-repeat right bottom / contain !important;
}
.theme-custom-encalm-light .theme-primary,
.theme-custom-encalm-light .isSelected.theme-primary .fbs-rating-img,
.theme-custom-encalm-light .isSelected.theme-primary .fbs-rating-title
{
    color: #c9a477 !important;
}
.theme-custom-encalm-light .theme-primary-bg {
    background-color: #c9a477 !important;
}
.theme-custom-encalm-light .theme-secondary-bg {
    background-color: #152248 !important;
}
.theme-custom-encalm-light .theme-primary-bg-dark {
    background-color: #DE7859 !important;
}
.theme-custom-encalm-light #footer .footer-text-visible {
    font-size: 0.86rem;
    color: #c9a477;
    text-shadow: 1px 1px 0 #ffffff96 !important;
}
.theme-custom-encalm-light .fbs-loc-staff-profile-img {
    border: 12px solid rgba(0,0,0,0.064);
}
.theme-custom-encalm-light #footer {
    overflow: visible;
    min-height: 120px;
    background: transparent;
}
/* OTP Button Positioning */
.theme-custom-encalm-light  .otp-validate-btn-container { right: 7px; top: 37px; }
.theme-custom-encalm-light .otp-verify-btn-container { right: 3px; top: 33px; }
