body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.app-font-alt {
    font-family: 'Open Sans', 'Segoe UI', 'Verdana', sans-serif;
}

.app-container {
    max-width: 1024px;
    margin: 0 auto;
}
.bg-dots {
    background: #FFF url("./assets/bg-wave.png") no-repeat left bottom / 600px;
}

.app-shadow-default {
    /*box-shadow: 0 0 28px rgba(150,172,214,0.15);*/
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
}
.isSelected .app-shadow-default {
    box-shadow: none;
}

hr.thin {
    height: 0;
    border: 0;
    border-top: 1px solid #dedede;
    margin: 10px 0;
    display: block;
}


/* OnsenUI overrides */
.page__content {
    background-color: #efeff4 !important;
}
.Page__fauxFullscreen .page .page__content,
.Page__fauxFullscreen .page .page__background--
{
    min-height: 100vh;
    background-color: #efeff4;
}

/* Htlm5 QR code overrides */
#html5qr-code-full-region {
    margin: 0;
    border-radius: 5px;
    border: 10px solid transparent !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    background: #FFF;
}
#html5qr-code-full-region__header_message { border-color: transparent !important; }
#html5qr-code-full-region__dashboard { margin-top: 10px }
#html5qr-code-full-region img[alt='Info icon'],
#html5qr-code-full-region__dashboard_section_swaplink { display: none !important; }
#html5qr-code-full-region button,
.btn-black
{
    padding: 7px 10px;
    margin-bottom: 5px;
    background: #202020;
    color: #FFF;
    border-radius: 2px;
    border: 1px solid #000;
    cursor: pointer;
}
.btn-blue {
    background: #25a6d9;
    border-color: #25a6d9;
}
.btn-over-image {
    border: 2px solid white;
    border-radius: 3px;
}
.grid-checklist {
    display: grid;
    grid-template-columns: 1fr 240px;
    grid-gap: 10px;
}
.grid-checklist-no-image {
    grid-template-columns: 1fr;
}
.grid-checklist-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-end;
}
.checklist-item-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.45rem;
    font-size: 0.9rem;
}
.checklist-item-footer .notification {
    line-height: 1.1;
}
@media all and (max-width: 590px) {
    .grid-checklist {
        grid-template-columns: 1fr;
    }
    .checklist-item-footer {
        text-align: left;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
    }
}

.notif-custom {
    position: absolute;
    z-index: 1000;
    width: calc(100% - 40px);
    margin: 20px;
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
    bottom: 0;
}

.form-required { color: #E20303 }
.error-inline,
.success-inline {
    font-size: 0.9rem;
    color: #E20303;
    display: block;
    padding: 7px 0 3px 3px;
    line-height: 1.1;
}
.success-inline {
    color: #749815 !important;
}

.bg-green-light { background-color: #f7fde6; }

/* Onsen UI Overrides * /
.page__background { background-color: #FFF !important; }
/* */
ons-toolbar:not([inline]) {
    position: static !important;
}
.nottification { line-height: 1 }

.form-input-hidden {
    height: 0;
    width: 0 !important;
    display: none !important;
}

/* Fullscreen */
.fullscreen-button-holder {
    position: absolute;
    right: 20px;
    top: 20px;
}

/* Form Errors */
.inline-error {
    font-size: 0.9rem;
    font-weight: 500;
    color: red;
}
.inline-error-chip {
    background: red; color: white;
    padding: 2px 4px;
    border-radius: 5px;
}
.inline-error-block {
    display: block;
    padding: 5px 10px 10px 0;
}

.card-box-simple {
    border: 1px solid #dedede;
    padding: 18px 18px 10px;
    margin: 15px auto 0;
    border-radius: 6px;
    background: white;
}

/* React-Tel-Input plugin -- Style Overrides */
.react-tel-input { font-family: inherit !important; }
.react-tel-input .country-list {
    width: calc(100vw - 100px) !important;
    min-width: 290px;
    max-width: 1000px; /* Match with FBS Container */
}
.react-tel-input .country-list .search-box {
    width: calc(100% - 40px);
    box-sizing: content-box;
}
